import * as React from "react"

import { SEO } from "../components/seo"
import { PrimaryHeader } from "../components/header"
import { Footer } from "../components/Footer"
import { clearSnappy } from "../model/helpers"

const TermsOfService = () => (
  <>
    {clearSnappy()}
    <PrimaryHeader />
    <SEO isQuiz={true} title="Terms Of Service" url="terms-of-service" />
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="body-wrap">
            <div className="row">
              <div className="col-sm-12 listicle-copy">
                <h1>Terms Of Service</h1>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 listicle-copy">
                <p>
                  <strong>TERMS AND CONDITIONS</strong>
                </p>
                <p>
                  <strong>Introduction</strong>
                </p>
                <p>
                  These terms and conditions govern your use of TriviaBoss.com (hereinafter, “this website”); by using
                  this website, you accept these terms and conditions in full.&nbsp;&nbsp; If you disagree with these
                  terms and conditions or any part of these terms and conditions, you must not use this website.
                </p>
                <p>
                  You must be at least [18] years of age to use this website. By using this website [and by agreeing to
                  these terms and conditions] you warrant and represent that you are at least [18] years of age.
                </p>
                <p>
                  This website uses cookies. By using this website and agreeing to these terms and conditions, you
                  consent to our use of cookies in accordance with the terms of our privacy
                  policy&nbsp;&amp;&nbsp;cookies policy.
                </p>
                <p>
                  <strong>License to use website</strong>
                </p>
                <p>
                  Unless otherwise stated, this website and/or its licensors own the intellectual property rights in the
                  website and material on the website. Subject to the license below, all these intellectual property
                  rights are reserved.
                </p>
                <p>
                  You may view, download for caching purposes only, and print pages from the website for your own
                  personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.
                </p>
                <p>You must not:</p>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li>republish material from this website (including republication on another website);</li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li>sell, rent or sub-license material from the website;</li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li>show any material from the website in public;</li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li>
                        reproduce, duplicate, copy or otherwise exploit material on this website for a commercial
                        purpose;
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li>edit or otherwise modify any material on the website; or</li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li>
                        redistribute material from this website except for content specifically and expressly made
                        available for redistribution.
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>
                  Where content is specifically made available for redistribution, it may only be redistributed within
                  your organization.
                </p>
                <p>
                  <strong>Acceptable use</strong>
                </p>
                <p>
                  You must not use this website in any way that causes, or may cause, damage to the website or
                  impairment of the availability or accessibility of the website; or in any way which is unlawful,
                  illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful
                  purpose or activity.
                </p>
                <p>
                  You must not use this website to copy, store, host, transmit, send, use, publish or distribute any
                  material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm,
                  keystroke logger, rootkit or other malicious computer software.
                </p>
                <p>
                  You must not conduct any systematic or automated data collection activities (including without
                  limitation scraping, data mining, data extraction and data harvesting) on or in relation to this
                  website without Policulous’s&nbsp;express written consent.
                </p>
                <p>You must not use this website to transmit or send unsolicited commercial communications.</p>
                <p>
                  You must not use this website for any purposes related to marketing without our express written
                  consent.
                </p>
                <p>
                  <strong>Restricted access</strong>
                </p>
                <p>
                  Access to certain areas of this website is restricted.&nbsp;We reserves the right to restrict access
                  to [other] areas of this website, or indeed this entire website, at our discretion.
                </p>
                <p>
                  If this website provides you with a user ID and password to enable you to access restricted areas of
                  this website or other content or services, you must ensure that the user ID and password are kept
                  confidential. This website may disable your user ID and password in our sole discretion without notice
                  or explanation.
                </p>
                <p>
                  <strong>User content</strong>
                </p>
                <p>
                  In these terms and conditions, “your user content” means material (including without limitation text,
                  images, audio material, video material and audio-visual material) that you submit to this website, for
                  whatever purpose.
                </p>
                <p>
                  You grant to this website a worldwide, irrevocable, non-exclusive, royalty-free license to use,
                  reproduce, adapt, publish, translate and distribute your user content in any existing or future media.
                  You also grant to our website the right to sub-license these rights, and the right to bring an action
                  for infringement of these rights.
                </p>
                <p>
                  Your user content must not be illegal or unlawful, must not infringe any third party’s legal rights,
                  and must not be capable of giving rise to legal action whether against you or our website or a third
                  party (in each case under any applicable law).
                </p>
                <p>
                  You must not submit any user content to the website that is or has ever been the subject of any
                  threatened or actual legal proceedings or other similar complaint.
                </p>
                <p>
                  We reserve the right to edit or remove any material submitted to this website, or stored on this
                  website’s servers, or hosted or published upon this website.
                </p>
                <p>
                  Notwithstanding our rights under these terms and conditions in relation to user content, this website
                  does not undertake to monitor the submission of such content to, or the publication of such content
                  on, this website.
                </p>
                <p>
                  <strong>No warranties</strong>
                </p>
                <p>
                  This website is provided “as is” without any representations or warranties, express or implied. This
                  website makes no representations or warranties in relation to this website or the information and
                  materials provided on this website.
                </p>
                <p>
                  Without prejudice to the generality of the foregoing paragraph, this website does not warrant that:
                </p>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li>this website will be constantly available, or available at all; or</li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li>the information on this website is complete, true, accurate or non-misleading.</li>
                    </ul>
                  </li>
                </ul>
                <p>
                  Nothing on this website constitutes, or is meant to constitute, advice of any kind. If you require
                  advice in relation to any [legal, financial or medical] matter you should consult an appropriate
                  professional.
                </p>
                <p>
                  <strong>Limitations of liability</strong>
                </p>
                <p>
                  This website will not be liable to you (whether under the law of contact, the law of torts or
                  otherwise) in relation to the contents of, or use of, or otherwise in connection with, this website:
                </p>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li>to the extent that the website is provided free-of-charge, for any direct loss;</li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li>for any indirect, special or consequential loss; or</li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li>
                        for any business losses, loss of revenue, income, profits or anticipated savings, loss of
                        contracts or business relationships, loss of reputation or goodwill, or loss or corruption of
                        information or data.
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>
                  These limitations of liability apply even if this website has been expressly advised of the potential
                  loss.
                </p>
                <p>
                  <strong>Exceptions</strong>
                </p>
                <p>
                  Nothing in this website disclaimer will exclude or limit any warranty implied by law that it would be
                  unlawful to exclude or limit; and nothing in this website disclaimer will exclude or limit our
                  liability in respect of any:
                </p>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li>death or personal injury caused by this website’s negligence;</li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li>fraud or fraudulent misrepresentation on the part of this website; or</li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li style={{ listStyleType: "none" }}>
                    <ul>
                      <li>
                        matter which it would be illegal or unlawful for this website to exclude or limit, or to attempt
                        or purport to exclude or limit, its liability.
                      </li>
                    </ul>
                  </li>
                </ul>
                <p>
                  <strong>Reasonableness</strong>
                </p>
                <p>
                  By using this website, you agree that the exclusions and limitations of liability set out in this
                  website disclaimer are reasonable.
                </p>
                <p>If you do not think they are reasonable, you must not use this website.</p>
                <p>
                  <strong>Other parties</strong>
                </p>
                <p>
                  You accept that, as a limited liability entity, this website has an interest in limiting the personal
                  liability of its officers and employees. You agree that you will not bring any claim personally
                  against this website, it’s officers or employees in respect of any losses you suffer in connection
                  with the website.
                </p>
                <p>
                  Without prejudice to the foregoing paragraph, you agree that the limitations of warranties and
                  liability set out in this website disclaimer will protect this website’s officers, employees, agents,
                  subsidiaries, successors, assigns and sub-contractors as well as this website.
                </p>
                <p>
                  <strong>Unenforceable provisions</strong>
                </p>
                <p>
                  If any provision of this website disclaimer is, or is found to be, unenforceable under applicable law,
                  that will not affect the enforceability of the other provisions of this website disclaimer.
                </p>
                <p>
                  <strong>Indemnity</strong>
                </p>
                <p>
                  You hereby indemnify this website and undertake to keep&nbsp;this website&nbsp;indemnified against any
                  losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any
                  amounts paid by this website to a third party in settlement of a claim or dispute on the advice
                  of&nbsp;this website’s legal advisers incurred or suffered by this website arising out of any breach
                  by you of any provision of these terms and conditions, or arising out of any claim that you have
                  breached any provision of these terms and conditions.
                </p>
                <p>
                  <strong>Breaches of these terms and conditions</strong>
                </p>
                <p>
                  Without prejudice to our other rights under these terms and conditions, if you breach these terms and
                  conditions in any way, this website may take such action as&nbsp;this website&nbsp;deems appropriate
                  to deal with the breach, including suspending your access to the website, prohibiting you from
                  accessing the website, blocking computers using your IP address from accessing the website, contacting
                  your internet service provider to request that they block your access to the website and/or bringing
                  court proceedings against you.
                </p>
                <p>
                  <strong>Variation</strong>
                </p>
                <p>
                  This website may revise these terms and conditions from time-to-time. Revised terms and conditions
                  will apply to the use of this website from the date of the publication of the revised terms and
                  conditions on this website. Please check this page regularly to ensure you are familiar with the
                  current version.
                </p>
                <p>
                  <strong>Assignment</strong>
                </p>
                <p>
                  This website may transfer, sub-contract or otherwise deal with our rights and/or obligations under
                  these terms and conditions without notifying you or obtaining your consent.
                </p>
                <p>
                  You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these
                  terms and conditions.
                </p>
                <p>
                  <strong>Severability</strong>
                </p>
                <p>
                  If a provision of these terms and conditions is determined by any court or other competent authority
                  to be unlawful and/or unenforceable, the other provisions will continue in effect. If any unlawful
                  and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part
                  will be deemed to be deleted, and the rest of the provision will continue in effect.
                </p>
                <p>
                  <strong>Entire agreement</strong>
                </p>
                <p>
                  These terms and conditions, together with our privacy policy,&nbsp;constitute the entire agreement
                  between you and this website in relation to your use of this website, and supersede all previous
                  agreements in respect of your use of this website.
                </p>
                <p>
                  <strong>Law and jurisdiction</strong>
                </p>
                <p>
                  These terms and conditions will be governed by and construed in accordance with United States Law, and
                  any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of
                  the state in which this website is based.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
)

export default TermsOfService
